<template>
  <div class="literatureLimit_container">
    <img class="drop_top_left" :src="getDropImg()" />
    <img class="drop_top_top" :src="getDropImg()" />
    <img class="drop_top_right" :src="getDropImg()" />
    <img class="drop_top_bottom" :src="getDropImg()" />
    <div class="vertical_right"></div>
    <div class="vertical_left"></div>
    <div class="literatureLimit_content">
      <div class="literatureLimit_title_block" :style="getStyle()">
        <div class="literatureLimit_title_number literatureLimit_key">
          <span> 序号 </span>
          <div class="literatureLimit_title_desc"></div>
        </div>
        <div class="literatureLimit_title_document literatureLimit_key">
          <span>{{is_courseware == 1?"课件":"文献"}}</span>
          <div class="literatureLimit_title_desc">{{is_courseware == 1?"":"点击文献标题了解内容详情"}}</div>
        </div>
        <div class="literatureLimit_title_people literatureLimit_key">
          <span> 已选人数 </span>
          <div class="literatureLimit_title_desc">
            每篇文献选择上限为{{ imitsNumber }}人
          </div>
        </div>
      </div>
      <div class="literatureLimit_content_scroll_block" ref="pageScroll">
        <div class="literatureLimit_content_scroll">
          <div
            class="literatureLimit_content_item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <literatureLimitItem
              :item="item"
              :index="index"
              :mainColor="mainColor"
              @goToDetail="goToDetail(item)"
              @doCommit="doCommit(item, index)"
              :imitsNumber="imitsNumber"
            ></literatureLimitItem>
          </div>
        </div>
      </div>
      <div class="literatureLimit_groupBtn">
        <el-button size="mini" @click="back" :style="getElBtnStyle()">返回</el-button>
      </div>
    </div>
    <popup :cfg="popupCfg" v-if="showChoose">
      <div class="doctorMeets_tip_content">
        <div class="doctorMeets_tip_content_text">
          文献选择确认后不能再进行更换，请慎重选择。
        </div>
        <div class="doctorMeets_add_page">
          <customButton
            class="doctorMeets_help_button"
            @click="doCloseChooseWin()"
          >
            取消
          </customButton>
          <customButton
            class="doctorMeets_help_button"
            @click="doSureChooseWin()"
          >
            确认
          </customButton>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import { ElButton } from "element-plus";
import BSScroll from "better-scroll";
import literatureLimitItem from "./literatureLimitItem.vue";
import customButton from "@/components/unit/customButton";
import popup from "@/components/unit/popup";
export default {
  name: "literatureLimit",
  data() {
    return {
      scroll:null,
      curChooseData: {},
      curChooseDataIndex: null,
      showChoose: false,
      requestApi: false,
      dataList: [],
      doctorInfo: {},
      imitsNumber: 0,
      project_id:"",
      mainColor:{},
      is_courseware:0,//页面是否以新模式(普贝希)展示
      dropImg:{
        37:"pubeixi_drop.png",
        38:"pubeixi_drop.png",
        def:"drop.png",
      },
      popupCfg: {
        title: "",
        content: "",
        button: [],
      },
    };
  },
  components: {
    literatureLimitItem,
    ElButton,
    customButton,
    popup,
  },

  created() {
    this.doctorInfo = this.$store.state.common.doctorInfo;
    if(!this.doctorInfo?.id) {
      this.doctorInfo.id = this.$route.query.doctorId;
    }
    this.initData();
    this.initScroll();
    document.getElementsByTagName("title")[0].innerHTML = this.is_courseware == 1?"课件选择专区":"文献选择专区";
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.pageScroll, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    getElBtnStyle(){
      return `background:${this.mainColor.fontColor}`;
    },
    getStyle(){
      return `color:${this.mainColor.fontColor}`;
    },
    getDropImg(){
      let img = this.dropImg[this.project_id]||this.dropImg.def;
      return require(`@/assets/images/literatureLimi/${img}`)
    },
    initData() {
      this.project_id = this.$tools.getStorage("projectId", sessionStorage) || this.$route.query.projectId;
      this.is_courseware = this.$tools.getStorage("is_courseware", sessionStorage);
      // console.log(this.is_courseware)
      this.mainColor = this.$config.mainColorConfig[this.project_id] || {fontColor:"#9b0047",bgColor:"#f7ecef"};
      let url = this.$tools.getURL(this.$urls.article.articleList, {
        project_id: this.project_id,
        period: this.$route.query.period,
        doctor_id: process.env.VUE_APP_doctorId || this.$route.query.doctor_id,
      });
      this.$axios.get(url).then((res) => {
        this.dataList = res.data;
        this.imitsNumber = this.dataList[0]?.courseware_number; //人数上限
        let is_choose_data = this.dataList.find((item) => item.is_choose);
        if (is_choose_data?.id) {
          let data = {
            courseware_id: is_choose_data.id,
            courseware_title: is_choose_data.class_content,
            new_courseware_number: `${is_choose_data.id}_${is_choose_data.new_courseware_number}`,
            the_right_courseware_id: is_choose_data.courseware_id,
          };
          this.$tools.setStorage("coursewareInfo", data, sessionStorage);
        }
        setTimeout(()=>{
          this.initScroll();
        },1000)
      });
    },
    goToDetail(item) {
      if(this.is_courseware == 1) return;
      const iHelpHim = "iHelpHim"; // 我帮专家选
      const is_choose_item = this.dataList.some((item) => item.is_choose);
      if (this.$route.query.t === iHelpHim) {
        this.$router.push({
          path: "/literatureLimitDetail",
          query: {
            id: item.id,
            courseware_id: item.courseware_id,
            imitsNumber: this.imitsNumber,
            doctor_id:this.$route.query.doctor_id,
            t: iHelpHim,
            is_choose_item,
          },
        });
        return;
      }
      this.$router.push({
        path: "/literatureLimitDetail",
        query: {
          id: item.id,
          meet_id: this.$route.query.id,
          imitsNumber: this.imitsNumber,
          is_choose_item,
        },
      });
    },
    doCloseChooseWin() {
      this.showChoose = false;
    },
    doSureChooseWin() {
      if(this.requestApi) {
        let item = this.curChooseData;
        let url = this.$urls.article.chooseArticle;
        let data = {
          article_id: item.id,
          meet_id: this.$route.query.id,
          project_id: item.project_id,
          period: item.period,
          doctor_id: this.doctorInfo.id,
          course_number: item.course_number,
        };
        this.$axios
          .post(url, data)
          .then((res) => {
            if (res.errcode) return;
            this.dataList[this.curChooseDataIndex].is_choose = 1; // 假数据回显
            this.dataList[this.curChooseDataIndex].new_courseware_number++; // 假数据回显
            this.$tips.success({ text: "选择成功" });
            this.doCloseChooseWin();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      this.dataList[this.curChooseDataIndex].is_choose = 1; // 假数据回显
      this.dataList[this.curChooseDataIndex].new_courseware_number++; // 假数据回显
      const data = {
        courseware_id: this.curChooseData.id,
        the_right_courseware_id: this.curChooseData.courseware_id,
        courseware_title: this.curChooseData.class_content,
        new_courseware_number: `${this.curChooseData.id}_${this.curChooseData.new_courseware_number}`,
      };
      this.doCloseChooseWin();
      this.$tools.setStorage("coursewareInfo", data, sessionStorage);
      this.$tips.success({ text: "选择成功" });
    },
    showChooseTip() {
      this.showChoose = true;
    },
    doCommit(item, index) {
      const iHelpHim = "iHelpHim"; // 我帮专家选
      if(item.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
        return;
      }
      if(item.new_courseware_number == this.imitsNumber && item.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
        return;
      }
      if (item.new_courseware_number == this.imitsNumber && !item.is_choose) {
        this.$tips.warning({ text: "该文献已选完" });
        return;
      }
      if (this.dataList.some(item=>item.is_choose)) {
        this.$tips.warning({ text: "文献已选择，不能更换" });
        return;
      }
      if (item.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
      } else if (this.$route.query.t === iHelpHim) {
        const is_choose_item = this.dataList.some((item) => item.is_choose);
        if (is_choose_item) {
          this.$tips.warning({ text: "文献已选择，不能更换" });
          return;
        }
        let coursewareInfo = this.$tools.getStorage(
          "coursewareInfo",
          sessionStorage
        );
        if (coursewareInfo?.courseware_id&&item.id===+coursewareInfo?.courseware_id) {
          this.$tips.warning({ text: "已选择该文献" });
          return;
        }
        if (coursewareInfo?.courseware_id) {
          this.$tips.warning({ text: "文献已选择，不能更换" });
          return;
        }
        this.requestApi = false;
        this.showChooseTip();
        this.curChooseData = item;
        this.curChooseDataIndex = index;
      } else {
        this.requestApi = true;
        this.showChooseTip();
        this.curChooseData = item;
        this.curChooseDataIndex = index;
      }
    },
  },
};
</script>
<style>
.literatureLimit_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e6e6e6;
  padding: 4vw;
}

.literatureLimit_content {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  padding: 4vw 2vw;
  background: #fff;
  overflow: hidden;
  border-radius: 4vw;
  display: flex;
  flex-direction: column;
}

.literatureLimit_title_block {
  width: 100%;
  height: 20vw;
  display: flex;
  font-weight: bold;
  align-items: center;
  /* color: #aa2264; */
  border-bottom: 1px #ececec solid;
}
.literatureLimit_key {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.literatureLimit_title_number {
  width: 12vw;
}
.literatureLimit_title_document {
  flex: 1 0 0;
  overflow: hidden;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}
.literatureLimit_title_people {
  width: 25vw;
}
.literatureLimit_title_desc {
  height: 26px;
  color: #c6c6c6;
  font-size: 2vw;
  margin-top: 1vw;
  text-align: center;
}
.literatureLimit_content_scroll_block {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  overflow: hidden;
  /*display: flex;*/
  /*flex-direction: column;*/
}
.literatureLimit_content_scroll {
  width: 100%;
  /*display: flex;*/
  /*justify-content: start;*/
  /*flex-wrap: wrap;*/
}
.literatureLimit_content_item {
  width: 100%;
}
.literatureLimit_container ::-webkit-scrollbar {
  display: none;
}

.literatureLimit_container .vertical_right {
  height: 92%;
  width: 1x;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  right: 32vw;
}
.literatureLimit_container .vertical_left {
  height: 92%;
  width: 1px;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  left: 18.5vw;
}
.literatureLimit_container img {
  width: 1.5vw;
  height: 1.5vw;
}
.literatureLimit_container .drop_top_left {
  position: fixed;
  top: 6vw;
  left: 17.9vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_top {
  position: fixed;
  top: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_right {
  position: fixed;
  bottom: 6vw;
  left: 17.9vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_bottom {
  position: fixed;
  bottom: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.literatureLimit_groupBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vw;
}
.literatureLimit_groupBtn .el-button--mini {
  /* background: #9b0047; */
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 4vw;
}
.doctorMeets_tip_content {
  padding: 4vw 0;
}

.doctorMeets_tip_content_text {
  display: flex;
  align-items: center;
  height: 16vw;
  padding: 0 5vw;
}

.doctorMeets_add_page {
  display: flex;
  justify-content: center;
}
.doctorMeets_add_page .doctorMeets_help_button {
  flex: 30% 0 0;
  padding: 2vw 0;
}
.doctorMeets_add_page .doctorMeets_help_button:first-child {
  margin-right: 5vw;
}
</style>
