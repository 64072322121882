<template>
  <div class="literatureLimit_container">
    <img class="drop_top_left" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_top" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_right" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_bottom" :src="require('@/assets/images/drop.png')" />
    <div class="vertical_right"></div>
    <div class="vertical_left"></div>
    <div class="literatureLimit_content">
      <div class="literatureLimit_title_block">
        <div class="literatureLimit_title_number literatureLimit_key">
          <span> 序号 </span>
          <div class="literatureLimit_title_desc"></div>
        </div>
        <div class="literatureLimit_title_document literatureLimit_key">
          <span>文献</span>
          <div class="literatureLimit_title_desc">点击文献标题了解内容详情</div>
        </div>
        <div class="literatureLimit_title_people literatureLimit_key">
          <span> 已选人数 </span>
          <div class="literatureLimit_title_desc">
            每篇文献选择上限为{{ imitsNumber }}人
          </div>
        </div>
      </div>
      <div class="literatureLimit_content_scroll_block" ref="pageScroll">
        <div class="literatureLimit_content_scroll">
          <div
            class="literatureLimit_content_item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <literatureLimitItem
              :item="item"
              :index="index"
              @goToDetail="goToDetail(item.id)"
              @doCommit="doCommit(item)"
              :imitsNumber="imitsNumber"
            ></literatureLimitItem>
          </div>
        </div>
      </div>
      <div class="literatureLimit_groupBtn">
        <el-button size="mini">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ElButton } from "element-plus";
import BSScroll from "better-scroll";
import literatureLimitItem from "./literatureLimitItem.vue";

export default {
  name: "literatureLimit",
  data() {
    return {
      dataList: [],
      doctorInfo: {},
      imitsNumber: 0,
    };
  },
  components: {
    literatureLimitItem,
    ElButton,
  },

  created() {
    this.doctorInfo = this.$store.state.common.doctorInfo;

    this.initData();
    this.initScroll();
    document.getElementsByTagName("title")[0].innerHTML = "挑战赛文献选择专区";
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.pageScroll, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    initData() {
      let url = this.$tools.getURL(this.$urls.article.articleList, {
        project_id: this.$tools.getStorage("projectId", sessionStorage),
        period: 1,
        doctor_id: this.doctorInfo.id,
      });
      this.$axios.get(url).then((res) => {
        this.dataList = res.data;
        this.imitsNumber = this.dataList[0].courseware_number; //人数上限
      });
    },
    goToDetail(id) {
      console.log(3333);
      this.$router.push({
        path: "/literatureLimitDetail",
        query: {
          id: id,
          imitsNumber: this.imitsNumber,
        },
      });
    },
    doCommit(item) {
      if (item.new_courseware_number == this.imitsNumber) {
        this.$tips.warning({ text: "该文献已选完" });
        return;
      }
      if (item.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
        return;
      } else {
        let url = this.$urls.article.chooseArticle;
        let data = {
          article_id: item.id,
          project_id: item.project_id,
          period: item.period,
          doctor_id: this.doctorInfo.id,
          course_number: item.course_number,
        };
        this.$axios
          .post(url, data)
          .then((res) => {
            if (res.errcode) return;
            item.new_courseware_number++;
            item.is_choose = 1;
            this.$tips.success({ text: "选择成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.literatureLimit_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e6e6e6;
  padding: 4vw;
}

.literatureLimit_content {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  padding: 4vw 2vw;
  background: #fff;
  overflow: hidden;
  border-radius: 4vw;
  display: flex;
  flex-direction: column;
}

.literatureLimit_title_block {
  width: 100%;
  height: 20vw;
  display: flex;
  font-weight: bold;
  align-items: center;
  color: #aa2264;
  border-bottom: 1px #ececec solid;
}
.literatureLimit_key {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.literatureLimit_title_number {
  width: 10vw;
}
.literatureLimit_title_document {
  flex: 1 0 0;
  overflow: hidden;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}
.literatureLimit_title_people {
  width: 25vw;
}
.literatureLimit_title_desc {
  height: 26px;
  color: #c6c6c6;
  font-size: 2vw;
  margin-top: 1vw;
  text-align: center;
}
.literatureLimit_content_scroll_block {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.literatureLimit_content_scroll {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.literatureLimit_content_item {
  width: 100%;
}
.literatureLimit_container ::-webkit-scrollbar {
  display: none;
}

.literatureLimit_container .vertical_right {
  height: 92%;
  width: 1x;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  right: 32vw;
}
.literatureLimit_container .vertical_left {
  height: 92%;
  width: 1px;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  left: 16.5vw;
}
.literatureLimit_container img {
  width: 1.5vw;
  height: 1.5vw;
}
.literatureLimit_container .drop_top_left {
  position: fixed;
  top: 6vw;
  left: 15.9vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_top {
  position: fixed;
  top: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_right {
  position: fixed;
  bottom: 6vw;
  left: 15.9vw;
  z-index: 9;
}
.literatureLimit_container .drop_top_bottom {
  position: fixed;
  bottom: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.literatureLimit_groupBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vw;
}
.literatureLimit_groupBtn .el-button--mini {
  background: #9b0047;
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 4vw;
}
</style>
