<template>
  <div class="literatureLimitItem_container" :style="getTopColor(index)">
    <div class="literatureLimitItem_title_number literatureLimitItem_key">
      {{ item.id }}
    </div>
    <div
      class="literatureLimitItem_title_document literatureLimitItem_key"
      @click="goToDetail()"
    >
      {{ item.class_content }}
    </div>
    <div class="literatureLimitItem_title_people literatureLimitItem_key">
      {{ item.new_courseware_number }}
      <el-button
        v-if="item.new_courseware_number < imitsNumber"
        size="mini"
        :style="{
          background:
            config.buttonGroup[item.is_choose == 1 ? 1 : 0].background,
        }"
        @click="doCommit()"
        >{{ config.buttonGroup[item.is_choose == 1 ? 1 : 0].title }}</el-button
      >
      <el-button
        v-else
        size="mini"
        :style="{
          background: config.buttonGroup[2].background
        }"
        @click="doCommit()"
        >{{ config.buttonGroup[2].title }}</el-button
      >
    </div>
  </div>
</template>
<script>
import config from "@/common/config";
import { ElButton } from "element-plus";
export default {
  name: "literatureLimitItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    imitsNumber: {
      type: Number,
      default: () => {
        return 15;
      },
    },
  },
  components: {
    ElButton,
  },
  data() {
    return {
      config: {
        buttonGroup: config.buttonGroup,
      },
    };
  },
  methods: {
    getTopColor(index) {
      if (index == 0) return `color:#aa2264`;
      if (index % 2 !== 0) return "background:#f7ecef";
    },
    doCommit() {
      this.$emit("doCommit", this.item);
    },
    goToDetail() {
      this.$emit("goToDetail", this.item.id);
    },
  },
};
</script>
<style>
.literatureLimitItem_container {
  width: 100%;
  height: 24vw;
  display: flex;
  font-size: 4vw;
  align-items: center;
  justify-content: center;
  color: #777777;
  padding: 2vw 0;
}
.literatureLimitItem_key {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.literatureLimitItem_title_number {
  width: 10vw;
  font-weight: bold;
  /* border-right: 1px #ececec solid; */
}
.literatureLimitItem_title_document {
  flex: 1 0 0;
  word-break: break-all;
  padding: 0 2vw;
}
.literatureLimitItem_title_people {
  /* border-left: 1px #ececec solid; */
  width: 25vw;
  font-weight: bold;
}
.literatureLimitItem_key {
}
.literatureLimitItem_active {
  background: #f7ecef;
}
.literatureLimitItem_container .el-button--mini {
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 3vw;
}
</style>
